<template>
	<div class="animated fadeIn">
		<b-card
			title="Asset History Log"
			sub-title="History logs for all changes in asset status"
		>
			<b-container class="mt-4">
				<loading
					:active.sync="isLoading"
					loader="spinner"
					color="#20A8D8"
					:is-full-page="false"
				/>

				<b-card>
					<b-row>
						<b-col md="12" sm="12" class="my-1">
							<b>FILTER OPTIONS</b>
						</b-col>
					</b-row>
					<b-row no-gutters>
						<b-col lg="4" md="10" sm="12" class="mr-2">
							<b-form-group label="Asset Code">
								<b-form-input
									id="assetId"
									name="Asset Id"
									type="search"
									v-model="filterBy.assetId"
									v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }"
								></b-form-input>
								<span v-show="errors.has('Asset Id')" class="help-block">{{
									errors.first('Asset Id')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row no-gutters>
						<b-col sm="12">
							<b-button class="mr-1" variant="success" @click="searchHistory">
								Search
							</b-button>
							<b-button class="mr-1" variant="primary" @click="resetFilters">
								Reset
							</b-button>
						</b-col>
					</b-row>
				</b-card>

				<div v-if="hasResult">
					<div class="btn-table-options">
						<b-row>
							<b-col sm="6" md="3">
								<b-dropdown
									text=" Select Actions "
									variant="dark"
									slot="append"
								>
									<b-dropdown-item>
										<json-excel
											:data="exportData"
											:fields="exportFields"
											type="xls"
											:name="fileName + '.xls'"
											>Export Asset History Log in Excel</json-excel
										>
									</b-dropdown-item>
									<b-dropdown-item>
										<json-excel
											:data="exportData"
											:fields="exportFields"
											type="csv"
											:name="fileName + '.csv'"
											>Export Asset History Log to CSV</json-excel
										>
									</b-dropdown-item>
								</b-dropdown>
							</b-col>
							<b-col sm="6" md="3" offset-md="6" class="my-1 text-right-md">
								<b-input-group prepend="Show" append="/ Page">
									<b-form-select :options="pageOptions" v-model="perPage" />
								</b-input-group>
							</b-col>
						</b-row>
					</div>

					<b-row>
						<b-col sm="8" class="my-2">
							<b>ASSET HISTORY LOG FOR</b>&nbsp;
							<b-badge variant="primary">{{ searchedAssetCode }}</b-badge>
						</b-col>
					</b-row>

					<b-table
						show-empty
						striped
						hover
						:items="items"
						:fields="fields"
						:current-page="currentPage"
						:per-page="perPage"
						:filter="filter"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:sort-direction="sortDirection"
						:sort-compare="sortCompare"
						responsive
					/>
					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="totalDisplay">Total: {{ totalRows }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination
								align="right"
								:total-rows="totalRows"
								:per-page="perPage"
								v-model="currentPage"
								class="my-0"
							/>
						</b-col>
					</b-row>
				</div>
				<div v-else>
					<b-card> No Results Found. </b-card>
				</div>
			</b-container>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { SortUtil } from '@/utils/sortUtil';

// Others
import JsonExcel from 'vue-json-excel';
import Datepicker from 'vuejs-datepicker';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'asset-history-log',
	components: {
		JsonExcel,
		Datepicker,
		Loading,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'dateCreated',
					sortable: true,
				},
				{
					key: 'operation',
				},
				{
					key: 'oldStatus',
				},
				{
					key: 'newStatus',
				},
				{
					key: 'origin',
					label: 'Owner',
				},
				'currentLocation',
				'createdBy',
				'notes',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: 'dateCreated',
			sortDesc: false,
			sortDirection: 'desc',
			filter: null,

			filterBy: {
				assetId: '',
			},

			searchedAssetCode: '',

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			hasResult: false,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Asset Id': 'assetId',
				Operation: 'operation',
				'Old Status': 'oldStatus',
				'New Status': 'newStatus',
				'Current Location': 'currentLocation',
				'Next Location': 'nextLocation',
				Origin: 'origin',
				'Current Company': 'currCompany',
				'Date Created': 'dateCreated',
				'Logged By': 'createdBy',
				Notes: 'notes',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return (
				'AssetHistoryLog-' + DateUtil.getDateInMMDDYYYYFormat(currTimeStamp)
			);
		},
	},
	methods: {
		isValidAssetCode(assetCode) {
			let assetTypesObj = this.$store.getters.assetTypes;
			return (
				_.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(assetTypesObj, assetCode)
			);
		},
		async validateParam(assetCode) {
			let validateResult = {
				isSuccess: true,
				errorMsg: '',
			};

			if (assetCode.length === 0) {
				validateResult.isSuccess = false;
				validateResult.errorMsg = 'Asset Code is required.';
			} else if (!this.isValidAssetCode(assetCode)) {
				validateResult.isSuccess = false;
				validateResult.errorMsg = `Invalid Asset Code. "${assetCode}" doesn't follow any of your asset tagging format.`;
			}

			if (this.isSuperAdmin || !validateResult.isSuccess) {
				return validateResult;
			} else {
				let isAccountable = await this.isCompanyAccountableToAsset(assetCode);
				if (!isAccountable) {
					validateResult.isSuccess = false;
					validateResult.errorMsg =
						'Invalid Access. You are not associated to this asset code.';
				}
				return validateResult;
			}
		},
		async isCompanyAccountableToAsset(assetCode) {
			let asset = await this.$store.dispatch('getAssetById', assetCode);

			if (asset.status === 'Inactive') {
				return asset.originId === this.loggedUserCompany.id;
			} else if (
				asset.status === 'Stored' ||
				asset.status === 'In-Transit' ||
				asset.status === 'Lost'
			) {
				let param = {
					assetTypeId: asset.assetTypeId,
					assetCode: assetCode,
					companyId: this.loggedUserCompany.id,
				};
				let companyAssetPool = await this.$store.dispatch(
					'getCompanyAssetPool',
					param
				);
				param.companyAssetPoolId = companyAssetPool.id;

				let taggedAsset = await this.$store.dispatch('getTaggedAsset', param);
				return !_.isEmpty(taggedAsset);
			}
		},
		sortCompare(rowA, rowB, key) {
			switch (key) {
				case 'dateCreated':
					return new Date(rowB.dateCreated) - new Date(rowA.dateCreated);
				default:
					return null;
			}
		},
		async searchHistory() {
			let assetCode = this.filterBy.assetId;

			let validateResult = await this.validateParam(assetCode);
			if (!validateResult.isSuccess) {
				this.$toaster.warning(validateResult.errorMsg);

				// Hide Search Results
				this.hasResult = false;
				this.searchedAssetCode = '';
				return;
			}

			// Show Loading Indicator
			this.isLoading = true;

			try {
				this.items = [];

				let results = await this.$store.dispatch('getAssetHistories', {
					assetId: assetCode,
				});

				let resultsArr = Object.values(results);
				resultsArr.forEach((result) => {
					let item = {
						assetId: result.assetId,
						operation: result.operation,
						oldStatus: this.getStatus(result.oldStatus),
						newStatus: result.newStatus,
						currentLocation: this.getLocationName(
							result.currentLocation
						),
						nextLocation: this.getLocationName(result.nextLocation),
						origin: this.getEntityName(result.origin),
						currCompany: this.getEntityName(result.currCompany),
						dateCreated: DateUtil.getFormattedDateWithTime(
							result.dateCreated
						),
						notes: result.notes,
						createdBy: result.createdBy,
					};

					this.items.push(item);
					this.totalRows = this.items.length;
					this.items = SortUtil.sortByDateDesc('dateCreated', this.items);
				});

				// Show Search Results
				this.hasResult = true;
				this.searchedAssetCode = assetCode;
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		getStatus(status) {
			if (!status) {
				return 'N/A';
			}
			return status;
		},
		getLocationName(loc) {
			if (loc && loc.company && loc.storageLocation) {
				return loc.company + ' - ' + loc.storageLocation;
			}
			return 'N/A';
		},
		getEntityName(entity) {
			if (entity) {
				return entity.name;
			}
			return 'N/A';
		},
		resetFilters() {
			this.filterBy = {
				dateCreated: null,
				assetId: '',
			};

			this.hasResult = false;

			// reset item content
			this.items = [];
		},
	},
};
</script>

<style scoped>
</style>
